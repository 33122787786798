import * as Sentry from '@sentry/react';

interface SentryConfig {
  accountName: string;
  id: string;
  organizationName: string;
  userFullName: string;
  username: string;
}

// This a temporary solution for setting environment. It will be fixed with GitHub migration
const getEnvironment = () => {
  const { hostname } = window.location;

  const env = hostname.split('.')[0];
  if (env === 'app') {
    return 'production';
  }
  if (env === 'staging') {
    return 'staging';
  }
  if (env === 'uat') {
    return 'uat';
  }
  if (env === 'dev') {
    return 'dev';
  }
  if (env === 'localhost') {
    return 'localhost';
  }

  return 'unknown';
};

const initializeSentry = (customData: SentryConfig) => {
  const environment = getEnvironment();

  if (environment === 'dev') {
    return;
  }

  const ignoreErrors = getErrorsToIgnore();

  Sentry.init({
    dsn: 'https://8d307f693f7c4be7859c3f7fc26de61a@sentry.io/1190009',
    environment,
    ignoreErrors,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  configureCustomData(customData);
};

function configureCustomData({
  id,
  username,
  organizationName,
  accountName,
  userFullName,
}: SentryConfig) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id, username });
    scope.setExtra('organizationName', organizationName);
    scope.setExtra('accountName', accountName);
    scope.setExtra('userFullName', userFullName);
  });
}

function getErrorsToIgnore() {
  return [
    // Ignoring this error after plenty of research. Here's a handy link
    // to stack overflow https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.'
  ];
}

export default initializeSentry;
