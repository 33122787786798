import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies
import Mousetrap from 'mousetrap'; // eslint-disable-line import/no-extraneous-dependencies
import axios from 'axios';
import qs from 'qs';

import initializeSentry from 'mineralsoft/config/sentry';

// # DJANGO AJAX HAX
function csrfSafeMethod(method) {
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

function getCsrfToken() {
  return jsdata.csrfToken;
}

const csrftoken = getCsrfToken();
$.ajaxSetup({
  crossDomain: false,
  beforeSend(xhr, settings) {
    if (!csrfSafeMethod(settings.type)) { xhr.setRequestHeader('X-CSRFToken', csrftoken); }
  },
});

// Axios CSRF setup
const stateChangingMethods = ['post', 'put', 'patch', 'delete'];
stateChangingMethods.forEach((requestMethod) => {
  axios.defaults.headers[requestMethod]['X-CSRFToken'] = csrftoken;
});

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

initializeSentry({
  id: window.jsdata.accountSlug,
  username: window.jsdata.accountName,
  organizationName: window.jsdata.organizationName,
  accountName: window.jsdata.accountName,
  userFullName: window.jsdata.userFullName,
});

// Reverse engineers a number in scientific notation to display in standard notation
// Math sorcery here
window.toStandardNotationNumber = (x) => {
  const radix = 10;
  let fixed = x;
  if (Math.abs(fixed) < 1.0) {
    const e = parseInt(fixed.toString().split('e-')[1], radix);
    if (e) {
      fixed *= 10 ** (e - 1);
      fixed = `0.${(new Array(e)).join('0') + fixed.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(fixed.toString().split('+')[1], radix);
    if (e > 20) {
      e -= 20;
      fixed /= 10 ** e;
      fixed += (new Array(e + 1)).join('0');
    }
  }
  return fixed;
};

window.queryParams = () => {
  const query = window.location.search.substring(1);
  const params = {};
  if (query !== '') {
    const rawVars = query.split('&');
    rawVars.forEach((rawVar) => {
      const [key, val] = rawVar.split('=');
      params[key] = decodeURIComponent(val);
    });
  }
  return params;
};

class _Tracking {
  constructor() {
    this.traits = this.getTraits();
    this.constructor.pageview();
  }

  static getStoredTraits() {
    try {
      const str = window.localStorage.user_traits;
      return JSON.parse(str);
    } catch (error) {
      return {};
    }
  }

  static getQueryTraits() {
    const traits = {};
    const qp = window.queryParams();
    Object.entries(qp).forEach(([key, value]) => {
      if (key.indexOf('a_') === 0) {
        const cleanedKey = key.slice(2, key.length);
        traits[cleanedKey] = value;
      }
    });
    return traits;
  }

  getTraits() {
    const traits = this.constructor.getQueryTraits();
    Object.assign(traits, this.constructor.getStoredTraits());
    try {
      window.localStorage.user_traits = JSON.stringify(traits);
    } catch (error) {
      // do nothing
    }
    return traits;
  }

  // eslint-disable-next-line class-methods-use-this
  identify() {
    if (window.jsdata.userEmail) {
      window.ga('set', 'userId', window.jsdata.userEmail);
      const {
        accountName,
        userEmail,
        userFullName,
        organizationName,
      } = window.jsdata;

      window.FS.identify(`${organizationName}__${userEmail}`, {
        displayName: userFullName || 'NO NAME',
        organizationName_str: organizationName,
        accountName_str: accountName,
        email: userEmail,
      });

      window.pendo && window.pendo.initialize({
        visitor: {
          id: `${organizationName}__${userEmail}`,
          email: userEmail,
          account: accountName,
        },
        account: {
          id: organizationName,
        },
      });
    }

    if (window.jsdata.account_name) {
      window.ga('set', 'clientId', window.jsdata.accountName);
    }
  }

  static pageview() {
    window.ga('send', 'pageview');
  }

  static track(name) {
    window.ga('send', 'event', name, null);
  }
}

window.myT = new _Tracking();

window.parsePath = () => {
  if (window.location.pathname.indexOf('/well_record') === 0) {
    return {
      object: 'well_record',
      slug: window.location.pathname.match(/well_record\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/operator') === 0) {
    return {
      object: 'operator',
      slug: window.location.pathname.match(/operator\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/producing_entity') === 0) {
    return {
      object: 'producing_entity_account',
      slug: window.location.pathname.match(/my_well\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/my_well') === 0) {
    return {
      object: 'producing_entity_account',
      slug: window.location.pathname.match(/my_well\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/statement') === 0) {
    return {
      object: 'statement',
      slug: window.location.pathname.match(/statement\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/division_order') === 0) {
    return {
      object: 'division_order',
      slug: window.location.pathname.match(/division_order\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/lease') === 0) {
    return {
      object: 'lease',
      slug: window.location.pathname.match(/lease\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/interest_group') === 0) {
    return {
      object: 'interest_group',
      slug: window.location.pathname.match(/interest_group\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/tract') === 0) {
    return {
      object: 'tract',
      slug: window.location.pathname.match(/tract\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/deed') === 0) {
    return {
      object: 'deed',
      slug: window.location.pathname.match(/deed\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/settlement_decimal') === 0) {
    return {
      object: 'settlement_decimal',
      slug: window.location.pathname.match(/settlement_decimal\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/acquisition') === 0) {
    return {
      object: 'acquisition',
      slug: window.location.pathname.match(/acquisition\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/divestiture') === 0) {
    return {
      object: 'divestiture',
      slug: window.location.pathname.match(/divestiture\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/jib') === 0) {
    return {
      object: 'jib',
      slug: window.location.pathname.match(/jib\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/afe') === 0) {
    return {
      object: 'afe',
      slug: window.location.pathname.match(/afe\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/joa') === 0) {
    return {
      object: 'joa',
      slug: window.location.pathname.match(/joa\/([\w-]*)/)[1],
    };
  } else if (window.location.pathname.indexOf('/drilling_spacing_unit') === 0) {
    return {
      object: 'drilling_spacing_unit',
      slug: window.location.pathname.match(/drilling_spacing_unit\/([\w-]*)/)[1],
    };
  }

  return {};
};

window.validate_password = async password => {
  /*
  Provide a password
  */

  const baseMessage = 'A strong password is hard to guess.  Use phrases, inside jokes, unusual speLLing, and other non-obvious numbers or symbols.';
  const warningMessage = 'Almost there!  Try adding another word, changing to use unusual speLLing, or adding other non-obvious numbers or symbols.';
  const successMessage = 'Success!  Your password is now considered complex enough.';

  const {
    valid = false,
    results,
    min_strength: minEntropy,
  } = await $.post('/password_validation_api', { password }, null, 'json');
  const { entropy } = results;

  // Redraw the progress bar
  /*
  We need a percentage to measure the strength in the progress bar.
  The most reliable, consistent measure is current entropy.
  So, we get a percentage of entropy divided by the minimum strength considered acceptable.
  */
  const percentageStrength = Math.min(100, Math.floor(entropy / minEntropy * 100));
  let status;
  let tooltipMessage;
  if (percentageStrength >= 100) {
    status = 'success';
    tooltipMessage = successMessage;
  } else if (70 <= percentageStrength && percentageStrength < 100) {
    status = 'warning';
    tooltipMessage = warningMessage;
  } else {
    status = 'danger';
    tooltipMessage = baseMessage;
  }
  $('#progress-bar')
    .attr('style', `width: ${percentageStrength}%`)
    .attr('aria-valuenow', percentageStrength)
    .removeClass()
    .addClass(`progress-bar progress-bar-${status}`);
  $('#progress-help').attr('title', tooltipMessage);

  if (valid === true) {
    $('#progress-bar').prop('title', 'Valid Password');
    $('.btn-primary').prop('disabled', false);
  } else {
    $('#progress-bar').prop('title', 'Your password is not yet complex enough.  Try adding more characters to make a word or phrase, such as "I Like Big Passwords and I cannot lie".');
    $('.btn-primary').prop('disabled', true);
  }
};

$(() => {
  Mousetrap.bind('/', () => {
    $('#app-search-query').focus();
    return false;
  });
});
