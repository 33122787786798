import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'mineralsoft/components/common/constants';
import IconContext from './IconContext';

const themedSVGAttributes = ['stroke', 'fill'];
const Parent = styled.div`
  display: inline-block;
  ${props => props.size && `
    width: ${props.size};
    height: ${props.size};
  `}
  svg {
    max-width: 100%;
    max-height: 100%;
    // Set the colors for each themable attribute only if it already existed
    ${props => themedSVGAttributes.map(attr => `
      & *[${attr}] {
        ${attr}: ${Colors[props.color] || props.color || 'currentColor'};
      }
    `)}
    }
  }
`;

export const IconNames = IconContext.keys().map((filepath) => {
  let formattedFilePath = filepath;
  const isIconFullPath = formattedFilePath.startsWith('/');
  if (isIconFullPath) {
    formattedFilePath = formattedFilePath.slice(formattedFilePath.lastIndexOf('/') + 1);
  }

  return formattedFilePath.replace(/(\.\/|\.svg)/g, '');
});

export default function Icon(props) {
  if (!IconNames.includes(props.name)) { return null; }
  const svg = IconContext(`./${props.name}.svg`);

  return (
    <Parent
      className="icon"
      {...props}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string, //  Name of the icon file, without '.svg'. Icon files are in ./svgs/
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // Prefers MS color name, but falls back to CSS color values
  size: PropTypes.string, //  CSS size property, applied to width and height
  style: PropTypes.object,
  title: PropTypes.string,
};

Icon.defaultProps = {
  name: 'plus',
  color: '',
  size: '16px',
  style: undefined,
  title: undefined,
};
